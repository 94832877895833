import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, List, ListItem, IconButton, Paper, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { convertToCSV } from '../utils/processing';
import EditIcon from '@mui/icons-material/Edit';

const GoldButton = styled(Button)({
  background: 'linear-gradient(45deg, #FFD700 30%, #FF8C00 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 140, 0, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  transition: 'background 0.5s',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8C00 30%, #FFD700 90%)',
  },
});

const OptionsPanel = styled(Paper)({
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#333',
  color: '#fff',
  borderRadius: '10px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
  animation: 'glow 1.5s infinite alternate-reverse',
  '@keyframes glow': {
    from: {
      boxShadow: '0 0 5px #FFD700',
    },
    to: {
      boxShadow: '0 0 20px #FFD700',
    },
  },
});

function FileList({ files, onUpdateFiles, onRemoveFile, onEditContent, editingFileIndex, setEditingFileIndex, options = {} }) {
  const [displayCount, setDisplayCount] = useState(20);

  useEffect(() => {
    setDisplayCount(20);
  }, [files]);

  const handleLoadMore = () => {
    setDisplayCount(prevCount => Math.min(prevCount + 20, files.length));
  };

  const handleEditName = (index, newName) => {
    const updatedFiles = files.map((file, i) =>
      i === index ? { ...file, name: newName } : file
    );
    onUpdateFiles(updatedFiles);
  };

  const handleRenameFiles = () => {
    const renamedFiles = files.map((file, index) => ({
      ...file,
      name: `${index}.txt`, // Assuming the files are text files with .txt extension
    }));
    onUpdateFiles(renamedFiles);
  };

  const handleDownloadCSV = () => {
    convertToCSV(files);
  };

  return (
    <Box>
      {options.outputAsCSV && (
        <OptionsPanel>
          <GoldButton onClick={handleDownloadCSV}>
            Download CSV
          </GoldButton>
        </OptionsPanel>
      )}
      <Box className="file-list" sx={{ textAlign: 'center' }}>
        <Typography variant="body1" className="file-count">Total Files: {files.length}</Typography>
        <List>
          {files.slice(0, displayCount).map((file, index) => (
            <ListItem key={file.name + index} sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                value={file.name}
                onChange={(e) => handleEditName(index, e.target.value)}
                variant="outlined"
                size="small"
                sx={{ flexGrow: 1, marginRight: 2 }}
                InputProps={{
                  style: {
                    color: '#000',
                  },
                }}
              />
              <IconButton onClick={() => onEditContent(index)} color="primary">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => onRemoveFile(index)} color="secondary">
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        {displayCount < files.length && (
          <Button onClick={handleLoadMore} variant="contained" className="load-more">Load More</Button>
        )}
      </Box>
    </Box>
  );
}

export default FileList;
