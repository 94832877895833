import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { getChatCompletion } from './api/openai';

// Example usage of the API connection
const exampleUsage = async () => {
  const messages = [{ role: 'user', content: 'Say this is a test!' }];
  try {
    const response = await getChatCompletion(messages);
    // Handle the response without logging sensitive information
  } catch (error) {
    console.error('Error:', error);
  }
};

exampleUsage();


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
