import axios from 'axios';

import { API_KEY, ORGANIZATION, PROJECT_ID } from './config';

const openai = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Authorization': `Bearer ${API_KEY}`,
    'OpenAI-Organization': ORGANIZATION,
    'OpenAI-Project': PROJECT_ID,
    'Content-Type': 'application/json',
  },
});

export const getChatCompletion = async (messages, model = 'gpt-4o-mini', temperature = 0.7) => {
  try {
    const systemMessage = {
      role: 'system',
      content: 'You are an assistant that provides all kinds of scripts and codes.',
    };
    const response = await openai.post('/chat/completions', {
      model,
      messages: [systemMessage, ...messages],
      temperature,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat completion:', error);
    throw error;
  }
};
