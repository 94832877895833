import React, { useState } from 'react';

function KeywordModal({ onSave, onClose }) {
  const [keywords, setKeywords] = useState('');

  const handleSave = () => {
    onSave(keywords.split(',').map(k => k.trim()));
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Enter Keywords or Phrases</h2>
        <textarea
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
          rows="5"
        />
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default KeywordModal;
