import React, { useState, useEffect } from 'react';
import { fetchScripts, applyScript } from '../api/supabase';
import './ScriptGallery.css';

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const ScriptGallery = ({ onApplyScript }) => {
  const [scripts, setScripts] = useState([]);
  const [visibleScripts, setVisibleScripts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [appliedScriptId, setAppliedScriptId] = useState(null);
  const scriptsPerPage = 8;

  useEffect(() => {
    const loadScripts = async () => {
      try {
        const fetchedScripts = await fetchScripts();
        setScripts(fetchedScripts);
        setVisibleScripts(fetchedScripts.slice(0, scriptsPerPage));
      } catch (err) {
        setError('Failed to load scripts');
      } finally {
        setLoading(false);
      }
    };

    loadScripts();
  }, []);

  const handleApplyScript = async (scriptId) => {
    try {
      const scriptContent = await applyScript(scriptId);
      onApplyScript({ content: scriptContent });
      setAppliedScriptId(scriptId);
    } catch (err) {
      // You might want to show an error message to the user here
      setError('Failed to apply script');
    }
  };

  const loadMore = () => {
    const startIndex = visibleScripts.length;
    const endIndex = startIndex + scriptsPerPage;
    setVisibleScripts([...visibleScripts, ...scripts.slice(startIndex, endIndex)]);
  };

  if (loading) return <div>Loading scripts...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="script-gallery">
      <h2>Public Script Gallery</h2>
      <div className="script-cards">
        {visibleScripts.length === 0 ? (
          <p>No scripts available.</p>
        ) : (
          visibleScripts.map((script, index) => (
            <div key={script.id} className={`script-card script-card-${index % 5 + 1}`}>
              <h3 title={script.description}>{truncateText(script.description, 50)}</h3>
              {appliedScriptId === script.id && (
                <p className="script-content" title={script.content}>
                  {truncateText(script.content, 100)}
                </p>
              )}
              <button onClick={() => handleApplyScript(script.id)}>
                {appliedScriptId === script.id ? 'Applied' : 'Apply'}
              </button>
            </div>
          ))
        )}
      </div>
      {scripts.length > visibleScripts.length && (
        <button onClick={loadMore}>Load More</button>
      )}
    </div>
  );
};

export default ScriptGallery;
