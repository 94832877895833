import React, { useState } from 'react';
import { Box, Typography, Button, Input } from '@mui/material';
import LoadingPopup from './LoadingPopup';

function FileUpload({ onUpload }) {
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    onUpload(files);
  };

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);
    onUpload(files);
  };

  return (
    <>
      {loading && <LoadingPopup />}
    <Box
      className={`file-upload ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        marginBottom: '20px',
        transition: 'all 0.3s ease',
        '&.dragging': {
          backgroundColor: '#f0f0f0',
          borderColor: '#999',
        }
      }}
    >
      <Typography variant="body1">Drag and drop files here 📁</Typography>
      <Typography variant="body1">or</Typography>
      <input
        type="file"
        onChange={handleFileInput}
        multiple
        style={{ display: 'none' }}
        id="fileInput"
      />
      <label htmlFor="fileInput">
        <Button variant="contained" component="span" className="file-input-label">
          Select Files
        </Button>
      </label>
    </Box>
    </>
  );
}

export default FileUpload;
