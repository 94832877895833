import React from 'react';
import JSZip from 'jszip/dist/jszip';
import { saveAs } from 'file-saver/dist/FileSaver';

function ResultsDisplay({ results }) {

  if (!results) return null;

  const handleDownload = () => {
    // Implement the logic to download the processed files as a zip
    const zip = new JSZip();
    results.data.forEach(file => {
      zip.file(file.name, file.content);
    });

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'processed_files.zip');
    });
  };

  const handleDownloadCSV = () => {
    const csvContent = results.data.map(file => file.content.replace(/\n/g, ' ')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'combined_results.csv');
  };
  return (
    <div className="results-display">
      <h2>Results 🖥️</h2>
      <p>{results.message}</p>
      {results.status === 'success' && (
        <>
          <h3>Processed Files:</h3>
          <button onClick={handleDownload} className="download-btn">Download Processed Files 📥</button>
          <button onClick={handleDownloadCSV} className="download-btn">Download Combined CSV 📄</button>
        </>
      )}
    </div>
  );
}

export default ResultsDisplay;
