import React, { useState, useRef } from 'react';
import LoadingPopup from './components/LoadingPopup';
import FileUpload from './components/FileUpload';
import OptionsPanel from './components/OptionsPanel';
import ResultsDisplay from './components/ResultsDisplay';
import FileList from './components/FileList';
import { convertToCSV } from './utils/processing'; // Adjusted import
import EditModal from './components/EditModal';
import KeywordModal from './components/KeywordModal';
import WorkGroupCard from './components/WorkGroupCard';
import ScriptGallery from './components/ScriptGallery';
import './App.css';
import dataScientistIcon from './assets/data-scientist-icon.png';
import contentManagerIcon from './assets/content-manager-icon.png';
import academicResearcherIcon from './assets/academic-researcher-icon.png';
import technicalWriterIcon from './assets/technical-writer-icon.png';
import developerIcon from './assets/developer-icon.png';
import seoSpecialistIcon from './assets/seo-specialist-icon.png';
import communityManagerIcon from './assets/community-manager-icon.png';
import ScriptModal from './components/ScriptModal';
import './components/LoadingButton.css';
import { saveAs } from 'file-saver';
import { API_KEY, ORGANIZATION, PROJECT_ID } from './api/config';
import { saveScript, setupScriptsTablePolicy } from './api/supabase';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const checkScriptsTablePolicy = async () => {
      await setupScriptsTablePolicy();
    };
    checkScriptsTablePolicy();
  }, []);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [options, setOptions] = useState({});
  const [results, setResults] = useState(null);
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [editingFileIndex, setEditingFileIndex] = useState(null);
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [generatedScript, setGeneratedScript] = useState('');
  const [scriptLoading, setScriptLoading] = useState(false);

  const downloadButtonRef = useRef(null);

  const handleUpload = (uploadedFiles) => {
    const readFiles = uploadedFiles.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ name: file.name, content: e.target.result });
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsText(file);
      });
    });

    Promise.all(readFiles).then(fileContents => {
      setFiles(prevFiles => [...prevFiles, ...fileContents]);
    }).catch(error => {
      console.error("Error reading files:", error);
    });
  };

  const handleOptionChange = (newOptions) => {
    if (newOptions.batchRemoveKeywords && !options.batchRemoveKeywords) {
      setShowKeywordModal(true);
    } else if (newOptions.generateCustomScript && !options.generateCustomScript) {
      setShowScriptModal(true);
    } else {
      setOptions(prevOptions => ({ ...prevOptions, ...newOptions }));
    }
  };

  const handleSaveKeywords = (keywords) => {
    setOptions(prevOptions => {
      let processedKeywords;
      if (Array.isArray(keywords)) {
        processedKeywords = keywords.map(k => k.trim());
      } else if (typeof keywords === 'string') {
        processedKeywords = keywords.split(',').map(k => k.trim());
      } else {
        console.error('Invalid keywords format:', keywords);
        processedKeywords = [];
      }
  
      return { 
        ...prevOptions, 
        keywords: processedKeywords,
        batchRemoveKeywords: processedKeywords.length > 0 
      };
    });
    setShowKeywordModal(false);
  };

  const handleSaveScriptDescription = async (description) => {
    setScriptLoading(true);
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
          'OpenAI-Organization': ORGANIZATION,
          'OpenAI-Project': PROJECT_ID
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: "You're a helpful assistant." },
            { role: "user", content: `Please generate a standalone JavaScript function for ${description}. The function should be encapsulated between specific markers so it can be easily extracted. Use the following format:

<start>
function processText(inputString) {
    // Your custom text processing logic
}
<end>

Only include the function code between <start> and <end> without any additional text or comments.` }
          ],
          temperature: 0.5
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API Error: ${response.status} - ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      if (data.choices && data.choices.length > 0) {
        const scriptContent = data.choices[0].message.content;
        setGeneratedScript(scriptContent);
        setOptions(prevOptions => ({ ...prevOptions, customScript: scriptContent }));
        
        // Save the script to the database
        const result = await saveScript({
          content: scriptContent,
          description: description
        });
        
        if (!result.success) {
          console.warn('Failed to save script to database:', result.error);
          console.warn('Script generation was successful, but saving failed.');
        } else {
          console.log('Script saved successfully:', result.data);
        }
      } else {
        throw new Error('No script generated from API');
      }
    } catch (error) {
      console.error('Error in script generation process:', error);
      setGeneratedScript(`Error: ${error.message}`);
    } finally {
      setScriptLoading(false);
    }
  };

  const handleClearFiles = () => {
    setFiles([]);
    setResults(null);
  };

  const handleUpdateFileName = (index, newName) => {
    setFiles(prevFiles => prevFiles.map((file, i) => 
      i === index ? { ...file, name: newName } : file
    ));
  };

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleProcess = async () => {
    if (files.length === 0) {
      setResults({ status: 'error', message: 'Please upload files first.' });
      return;
    }
  
    try {
      setLoading(true);
      console.log('Loading set to true');
      setResults({ status: 'processing', message: 'Processing files...' });
      
      const processedFiles = await convertToCSV(files, options);
  
      const totalFiles = files.length;
      const processedFilesCount = processedFiles.length;
      setTimeout(() => {
        setResults({
          status: 'success',
          message: `Total Texts: ${totalFiles}, Processed: ${processedFilesCount} 🚀`,
          data: processedFiles
        });
      }, 10000); // Delay results until after loading animation completes
  
      if (downloadButtonRef.current) {
        const scrollOffset = window.innerHeight * 0.2;
        window.scrollTo({
          top: downloadButtonRef.current.getBoundingClientRect().top + window.scrollY - scrollOffset,
          behavior: 'smooth'
        });
      }
    } catch (error) {
      console.error('Error processing files:', error);
      setResults({ status: 'error', message: 'Error processing files. Please try again.' });
    } finally {
      console.log('Loading set to false');
      setTimeout(() => {
        setLoading(false);
      }, 10000); // Hide after 10 seconds
    }
  };

  const [appliedScript, setAppliedScript] = useState(null);

  const handleApplyScript = (script) => {
    setAppliedScript(script);
    setOptions(prevOptions => ({
      ...prevOptions,
      customScript: script.content
    }));
  };

  const handleEditContent = (index) => {
    setEditingFileIndex(index);
  };

  const handleProcessContent = (processedContent) => {
    console.log('Handling processed content:', processedContent);
    // Update the state or perform any necessary actions with the processed content
  };

  return (
    <>
      {loading && (
        <>
          <LoadingPopup />
          {console.log('LoadingPopup should be displayed')}
        </>
      )}
      <div className="App">
        <header>
          <div className="header-container">
            <h1 className="shadow-header">TextTidy ✨🧹</h1>
            <p className="subtitle">*lightspeed batch text processing and cleaning service.*</p>
          </div>
          <a href="https://buymeacoffee.com/ogkai" className="buy-coffee">
            ☕ Buy Me A Coffee ❤️
          </a>
        </header>
        <main>
          <FileUpload onUpload={handleUpload} />
          {files.length > 0 && (
            <>
              <FileList 
                files={files} 
                onUpdateFiles={handleUpdateFileName} 
                onRemoveFile={handleRemoveFile}
                onEditContent={handleEditContent}
                editingFileIndex={editingFileIndex}
                setEditingFileIndex={setEditingFileIndex}
              />
              <button onClick={handleClearFiles}>Clear All Files</button>
            </>
          )}
          <OptionsPanel onChange={handleOptionChange} options={options} />
          {showKeywordModal && (
            <KeywordModal
              onSave={handleSaveKeywords}
              onClose={() => setShowKeywordModal(false)}
            />
          )}
          {showScriptModal && (
            <ScriptModal
              onSave={handleSaveScriptDescription}
              onClose={() => setShowScriptModal(false)}
              loading={scriptLoading}
              generatedScript={generatedScript}
              content={files[0]?.content} // Assuming we want to process the first file's content
              onProcessContent={handleProcessContent}
            />
          )}
          <button onClick={handleProcess} disabled={files.length === 0} ref={downloadButtonRef}>
            Process Files 🚀
          </button>
          <ResultsDisplay results={results} />
          {editingFileIndex !== null && (
            <EditModal 
              file={files[editingFileIndex]} 
              onSave={(newContent) => {
                setFiles(prevFiles => prevFiles.map((file, i) => 
                  i === editingFileIndex ? { ...file, content: newContent } : file
                ));
              }} 
              onClose={() => setEditingFileIndex(null)} 
            />
          )}
          {generatedScript && (
            <div className="generated-script">
              <h3>Generated Script:</h3>
              <pre>{generatedScript}</pre>
              <button onClick={() => {
                const blob = new Blob([generatedScript], { type: 'text/plain;charset=utf-8' });
                saveAs(blob, 'custom_script.js');
              }}>Download Script</button>
            </div>
          )}
        </main>
        <ScriptGallery onApplyScript={handleApplyScript} />
        <section className="work-groups">
          <h2>Who Can Benefit from Text Tidy?</h2>
          <div className="work-group-cards">
            <WorkGroupCard
              title="Data Scientists and Machine Learning Engineers"
              description="Automate tedious tasks like cleaning and preprocessing large datasets, especially for NLP tasks."
              faqs={[
                { question: "How can Text Tidy help with NLP tasks?", answer: "Text Tidy can automate the cleaning and preprocessing of large text datasets, saving time and reducing errors." },
                { question: "Can Text Tidy handle large datasets?", answer: "Yes, Text Tidy is designed to efficiently process large volumes of text data." }
              ]}
              icon={dataScientistIcon}
            />
            <WorkGroupCard
              title="Content Managers and Digital Marketers"
              description="Streamline repetitive editing tasks, ensuring consistent formatting and cleaning across all documents."
              faqs={[
                { question: "How does Text Tidy ensure consistent formatting?", answer: "Text Tidy applies predefined rules to maintain consistent formatting across all documents." },
                { question: "Can Text Tidy handle bulk content updates?", answer: "Yes, Text Tidy can process multiple documents simultaneously, making bulk updates easy." }
              ]}
              icon={contentManagerIcon}
            />
            <WorkGroupCard
              title="Academic Researchers"
              description="Prepare large corpora of text for qualitative analysis or text mining efficiently."
              faqs={[
                { question: "How can Text Tidy assist in text mining?", answer: "Text Tidy can preprocess and clean text data, making it ready for text mining and analysis." },
                { question: "Is Text Tidy suitable for qualitative analysis?", answer: "Yes, Text Tidy can help in preparing text data for qualitative research by removing unwanted text and standardizing formats." }
              ]}
              icon={academicResearcherIcon}
            />
            <WorkGroupCard
              title="Technical Writers"
              description="Standardize formats, remove unwanted text, and ensure consistency across multiple documents."
              faqs={[
                { question: "Can Text Tidy help with documentation updates?", answer: "Yes, Text Tidy can automate the process of updating and maintaining documentation." },
                { question: "How does Text Tidy ensure consistency?", answer: "Text Tidy applies consistent formatting rules across all documents, ensuring uniformity." }
              ]}
              icon={technicalWriterIcon}
            />
            <WorkGroupCard
              title="Developers and Programmers"
              description="Implement bulk changes efficiently in codebases involving large numbers of text files."
              faqs={[
                { question: "Can Text Tidy handle code files?", answer: "Yes, Text Tidy can process and clean code files, making bulk changes easy." },
                { question: "How does Text Tidy assist in code maintenance?", answer: "Text Tidy can automate repetitive tasks like updating configuration files and cleaning up code." }
              ]}
              icon={developerIcon}
            />
            <WorkGroupCard
              title="SEO Specialists"
              description="Update keywords, remove duplicates, and clean up meta descriptions in bulk."
              faqs={[
                { question: "How can Text Tidy improve SEO?", answer: "Text Tidy can automate the process of updating keywords and cleaning up meta descriptions, improving SEO efficiency." },
                { question: "Can Text Tidy handle large websites?", answer: "Yes, Text Tidy is designed to process large volumes of text, making it suitable for large websites." }
              ]}
              icon={seoSpecialistIcon}
            />
            <WorkGroupCard
              title="Community Managers"
              description="Filter out unwanted text and maintain quality standards in user-generated content."
              faqs={[
                { question: "How does Text Tidy help with content moderation?", answer: "Text Tidy can automate the process of filtering out unwanted text, making content moderation easier." },
                { question: "Can Text Tidy handle large volumes of user-generated content?", answer: "Yes, Text Tidy is designed to efficiently process large volumes of text, making it suitable for community management." }
              ]}
              icon={communityManagerIcon}
            />
          </div>
        </section>
        <footer className="footer">
          <span className="footer-text">Made by ogkai with love 🐱
          </span>
        </footer>
      </div>
    </>
  );
}

export default App;
