import React, { useState, useEffect } from 'react';
import './LoadingButton.css';

const ScriptModal = ({ onSave, onClose, loading, generatedScript, content, onProcessContent }) => {
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setIsLoading((prev) => !prev);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await onSave(description);
    } catch (error) {
      console.error('Error saving script:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false);
    }
  };

  // Commenting out the handleApply function as it is no longer needed
  // const handleApply = () => {
  //   if (generatedScript) {
  //     try {
  //       console.log('Attempting to create custom function from script:', generatedScript);
  //       const scriptMatch = generatedScript.match(/<start>([\s\S]*?)<end>/);
  //       if (scriptMatch) {
  //         const scriptContent = scriptMatch[1].trim();
  //         console.log('Extracted script content:', scriptContent);
  //         const customFunction = new Function('content', `
  //           if (typeof content !== 'string') {
  //             throw new Error('Content must be a string');
  //           }
  //           return (${scriptContent})(content);
  //         `);
  //         console.log('Custom function created successfully.');
  //         const processedContent = customFunction(content);
  //         console.log('Processed content:', processedContent);
  //         onProcessContent(processedContent); // Call the passed callback to handle the processed content
  //       } else {
  //         throw new Error('Custom script format is incorrect.');
  //       }
  //     } catch (error) {
  //       console.error('Error executing custom script:', error);
  //       console.error('Custom script content:', generatedScript);
  //     }
  //   }
  // };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Generate Custom Script</h2>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe the script you need..."
          disabled={loading}
        />
        <button
          className={isLoading ? 'loading' : ''}
          onClick={handleSave}
          disabled={loading || !description?.trim()}
        >
          {isLoading ? 'Generating...' : 'Generate'}
        </button>
        {/* Commenting out the Apply button */}
        {/* <button onClick={handleApply} disabled={loading || !generatedScript}>Apply</button> */}
        <button onClick={onClose} disabled={loading}>Close</button>
        {loading && <div className="loading">Loading...</div>}
        {generatedScript && (
          <div className="generated-script">
            <h3>Generated Script:</h3>
            <pre>{generatedScript}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScriptModal;
