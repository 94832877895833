import React, { useState, useEffect } from 'react';
import { processFileContent } from '../utils/processing';
import { styled, TextField } from '@mui/material';
import { saveScript } from '../api/supabase';

const OptionsPanelContainer = styled('div')({
  background: '#f5f5f5',
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Roboto, sans-serif',
});

const OptionsTitle = styled('h3')({
  fontSize: '1.5em',
  marginBottom: '20px',
  color: '#333',
});

const OptionItem = styled('label')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '15px',
  fontSize: '1em',
  color: '#555',
});

const KeywordsDisplay = styled('span')({
  fontSize: '0.9em',
  color: '#777',
  marginLeft: '25px',
});

const KeywordBox = styled('div')({
  display: 'inline-block',
  padding: '5px 10px',
  margin: '2px 0 10px 25px',
  background: '#e0e0e0',
  borderRadius: '5px',
  fontSize: '0.9em',
  color: '#333',
});

const CrossIcon = styled('span')({
  marginLeft: '10px',
  cursor: 'pointer',
  color: '#ff0000',
  fontWeight: 'bold',
  fontSize: '1.2em', // Increase the size of the cross icon
});

const Checkbox = styled('input')({
  marginRight: '10px',
  '& .MuiCheckbox-root': {
    color: '#800080',
  },
});

const KeywordInput = styled('input')({
  margin: '10px 0',
  padding: '5px',
  width: 'calc(100% - 10px)',
});

function OptionsPanel({ onChange, options }) {
  const [sampleText, setSampleText] = useState('a portrait of a character . In front of the blurred background, there is a car with a bag on the car seat. Behind the car, there is a man with dark brown complexion, curved eyebrows , &pound; double&#39; eyelids, thick lips, and wearing a striped coat. &#39;Wearing red pants, next to him, there is a dog. The dog&#39;s ears are big and drooping. the dog&#39;s fur is brown and there is a collar around its neck.');
  const [originalSampleText] = useState('a portrait of a character . In front of the blurred background, there is a car with a bag on the car seat. Behind the car, there is a man with dark brown complexion, curved eyebrows , &pound; double&#39; eyelids, thick lips, and wearing a striped coat. &#39;Wearing red pants, next to him, there is a dog. The dog&#39;s ears are big and drooping. the dog&#39;s fur is brown and there is a collar around its neck.');

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const newOptions = { 
      ...options, 
      [name]: type === 'checkbox' ? checked : value
    };
    if (name === 'generateCustomScript' && !checked) {
      newOptions.customScript = ''; // Clear customScript if the checkbox is unchecked
    }
    onChange(newOptions);
    updateSampleText(newOptions);
  };

  const handleRemoveKeyword = (indexToRemove) => {
    const newKeywords = options.keywords.filter((_, index) => index !== indexToRemove);
    onChange({ ...options, keywords: newKeywords });
  };

  const handleAddKeyword = (newKeyword) => {
    if (newKeyword && !options.keywords.includes(newKeyword)) {
      onChange({ ...options, keywords: [...options.keywords, newKeyword] });
    }
  };

  const updateSampleText = (newOptions) => {
    const keywordsArray = Array.isArray(newOptions.keywords) ? newOptions.keywords : (typeof newOptions.keywords === 'string' ? newOptions.keywords.split(';').map(k => k.trim()).filter(k => k) : []);
    const processedText = processFileContent(originalSampleText, newOptions);
    setSampleText(processedText);
  };

  useEffect(() => {
    updateSampleText(options);
  }, [options]);

  return (
    <OptionsPanelContainer>
      <OptionsTitle>Processing Options</OptionsTitle>
      <OptionItem>
        <Checkbox type="checkbox" name="addPrefix" checked={options.addPrefix || false} onChange={handleChange} />
        Add Prefix
      </OptionItem>
      {options.addPrefix && (
        <OptionItem>
          <TextField
            label="Enter prefix text..."
            variant="outlined"
            fullWidth
            name="prefixText"
            onChange={handleChange}
            value={options.prefixText || ''}
            margin="normal"
          />
        </OptionItem>
      )}
      <OptionItem>
        <Checkbox type="checkbox" name="addSuffix" checked={options.addSuffix || false} onChange={handleChange} />
        Add Suffix
      </OptionItem>
      {options.addSuffix && (
        <OptionItem>
          <TextField
            label="Enter suffix text..."
            variant="outlined"
            fullWidth
            name="suffixText"
            onChange={handleChange}
            value={options.suffixText || ''}
            margin="normal"
          />
        </OptionItem>
      )}
      <OptionItem>
        <Checkbox type="checkbox" name="keepFirstSentence" checked={options.keepFirstSentence || false} onChange={handleChange} />
        Keep only First sentence
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="normalizeText" checked={options.normalizeText || false} onChange={handleChange} />
        Text Normalization
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="removeStopWords" checked={options.removeStopWords || false} onChange={handleChange} />
        Remove Stop Words
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="replaceHtmlEntities" checked={options.replaceHtmlEntities || false} onChange={handleChange} />
        Replace HTML entities
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="keepFirstParagraph" checked={options.keepFirstParagraph || false} onChange={handleChange} />
        Keep only first paragraph
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="capitalizeSentences" checked={options.capitalizeSentences || false} onChange={handleChange} />
        Capitalize sentences
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="fixPunctuation" checked={options.fixPunctuation || false} onChange={handleChange} />
        Fix punctuation
      </OptionItem>
      <OptionItem>
        <Checkbox type="checkbox" name="batchRemoveKeywords" checked={options.batchRemoveKeywords || false} onChange={handleChange} />
        Batch remove keywords
      </OptionItem>
      {options.batchRemoveKeywords && (
        <>
          <TextField
            label="Add a keyword and press Enter"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddKeyword(e.target.value);
                e.target.value = '';
              }
            }}
            margin="normal"
          />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {options.keywords && options.keywords.map((keyword, index) => (
              <KeywordBox key={index}>
                {keyword}
                <CrossIcon onClick={() => handleRemoveKeyword(index)}>×</CrossIcon>
              </KeywordBox>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          </div>
        </>
      )}
      <OptionItem>
        <Checkbox type="checkbox" name="generateCustomScript" checked={options.generateCustomScript || false} onChange={handleChange} />
        Generate Custom Script
      </OptionItem>
      {options.generateCustomScript && (
        <OptionItem>
          <TextField
            label="Enter your custom script here..."
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="customScript"
            onChange={handleChange}
            value={options.customScript}
            margin="normal"
          />
        </OptionItem>
      )}
      <OptionsTitle>Sample Output</OptionsTitle>
      <div style={{ background: '#fff', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginTop: '20px' }}>
        <p>{sampleText}</p>
      </div>
    </OptionsPanelContainer>
  );
}

export default OptionsPanel;
