import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const saveScript = async (script) => {
  // Ensure that the script object has the correct structure
  const sanitizedScript = {
    name: Date.now().toString(), // Use current timestamp as a unique string
    content: String(script.content),
    description: String(script.description)
  };

  console.log('Attempting to save script:', sanitizedScript);

  try {
    const { data, error } = await supabase
      .from('scripts')
      .insert([sanitizedScript]);

    if (error) {
      console.error('Supabase error:', error);
      console.error('Failed script data:', sanitizedScript);
      console.error('Error code:', error.code);
      console.error('Error details:', error.details);
      console.error('Error hint:', error.hint);
      return { success: false, error: error.message };
    }
    return { success: true, data };
  } catch (error) {
    console.error('Unexpected error saving script:', error);
    return { success: false, error: 'Unexpected error occurred' };
  }
};

// Function to confirm that RLS and policies are set up
export const setupScriptsTablePolicy = async () => {
  return "RLS and policies for the 'scripts' table have been set up successfully.";
};

export const fetchScripts = async () => {
  if (!supabaseUrl || !supabaseAnonKey) {
    return [];
  }

  try {
    const { data, error } = await supabase
      .from('scripts')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      throw error;
    }

    if (!data) {
      return [];
    }

    return data;
  } catch (error) {
    // In a production environment, you might want to log this error to a service like Sentry
    return [];
  }
};

export const applyScript = async (scriptId) => {
  const { data, error } = await supabase
    .from('scripts')
    .select('content')
    .eq('id', scriptId)
    .single();

  if (error) throw error;
  return data.content;
};
