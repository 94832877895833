import React, { useState } from 'react';
import FAQSection from './FAQSection';
import './WorkGroupCard.css';

const WorkGroupCard = ({ title, description, faqs, icon }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="work-group-card">
      <div className="card-header" onClick={toggleExpand}>
        <img src={icon} alt={`${title} icon`} className="card-icon" />
        <h2>{title}</h2>
      </div>
      <p>{description}</p>
      {expanded && <FAQSection faqs={faqs} />}
    </div>
  );
};

export default WorkGroupCard;
