import React, { useState, useEffect } from 'react';

function EditModal({ file, onSave, onClose }) {
  const [content, setContent] = useState(file ? file.content : '');

  useEffect(() => {
    if (file) {
      setContent(file.content || '');
    }
  }, [file]);

  const handleSave = () => {
    onSave(content);
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Edit Content</h2>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows="10"
        />
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default EditModal;
