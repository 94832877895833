import React, { useEffect, useState } from 'react';
import './LoadingPopup.css';

const quotes = [
  "“The only way to do great work is to love what you do.” – Steve Jobs",
  "“Life is like riding a bicycle. To keep your balance, you must keep moving.” – Albert Einstein",
  "“The future belongs to those who believe in the beauty of their dreams.” – Eleanor Roosevelt",
  "“The best way to predict the future is to invent it.” – Alan Kay",
  "“In the middle of difficulty lies opportunity.” – Albert Einstein",
  "“The only limit to our realization of tomorrow is our doubts of today.” – Franklin D. Roosevelt",
  "“The purpose of our lives is to be happy.” – Dalai Lama",
  "“Your time is limited, so don’t waste it living someone else’s life.” – Steve Jobs",
  "“The best revenge is massive success.” – Frank Sinatra",
  "“The only way to achieve the impossible is to believe it is possible.” – Charles Kingsleigh"
];

const LoadingPopup = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
    }, 10000); // Minimum display time of 10 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="loading-popup" className="loading-popup">
      <div className="loading-content">
        <div className="emoji">🤖</div>
        <div className="quote">{quote}</div>
        <div className="emoji">🚀</div>
      </div>
    </div>
  );
};

export default LoadingPopup;
